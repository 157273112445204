import firebaseConfig from "./config"
import {initializeApp} from "firebase/app"
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  verifyBeforeUpdateEmail
} from "firebase/auth"


const app = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = getAuth(app)
    }
  }

  async loginWithCustomToken(customToken) {
    return signInWithCustomToken(this.auth, customToken)
    .then( async userCredential => {
      // L'utilisateur est maintenant connecté
      const user = userCredential.user;
      const userToken = await user.getIdToken();
      return ({
        userToken, 
        userCredential
      });
    })
    .catch((error) => ({
      error: true,
      msg: "login_with_custom_token_error",
      errorDetails: error
    }));
  }

  async refreshUserToken() {
    const user = this.auth.currentUser;
    return user.getIdToken()
  }

  async login({ email, password }) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  async logout() {
    await signOut(this.auth)
  }

  async resetPassword(email){
    return sendPasswordResetEmail(this.auth, email)
  }

  async updateDisplayName(displayName) {
    return updateProfile(this.auth.currentUser, {displayName})
  }
}

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase()
    return firebaseInstance
  } else if (firebaseInstance) {
    return firebaseInstance
  } else {
    return null
  }
}

export default getFirebaseInstance