export const sessions = {
    my_account_link: "/my-account/",
    payment_link: "/payment/",
    prices_link: "/pricing/",
}

export const useInitUser = {
    error_message: {
        user_token: "Sorry, we are unable to retrieve your information. Please refresh the page or try again later.",
        user_data: "Sorry, we are unable to retrieve your user information. Please refresh the page or try again later.",
        api_usage: "Sorry, we are unable to retrieve your API usage history. Please refresh the page or try again later.",
    },
}


export const useUpdateUserData = {

    //Global
    flash_payment_infos_error: "Sorry, we are unable to retrieve your payment information. Please refresh the page.",
    flash_update_empty: "There was no information to update.",
    flash_validate_captcha: "Please, validate the captcha to continue.",


    //Get invoices
    flash_no_more_invoices: "No more invoices to show.",
    flash_invoices_retrieving_error: "There was an error retrieving your invoices. Please try again later.",


    //Get subscription usage
    flash_subscription_usage_retrieving_error: "There was an error retrieving your subscription usage. Please try again later.",

    
    //Get api usage
    flash_current_usage_retrieving_error: "Sorry, we are unable to retrieve your API usage history. Please refresh the page or try again later.",


    //Send verification email
    flash_send_verification_email_sucess: userEmail => `An email has been sent to your e-mail address ${userEmail}.`,
    flash_send_verification_email_error: "An error has occurred, please try again later.",


    //Privacy
    flash_privacy_success:{
        anonymized: "Your anonymization preference has been updated successfully.",
        learnable: "Your learnability preference has been updated successfully.",
    },
    flash_privacy_error:{
        anonymized: "Your anonymization preference could not be updated. Please retry later.",
        learnable: "Your learnability preference could not be updated. Please retry later.",
    },


    //Limit
    flash_limit_success: {
        "hard limit": "Your hard limit has been updated successfully.",
        "soft limit": "Your soft limit has been updated successfully.",
    },
    flash_limit_error: {
        "hard limit": "Your hard limit could not be updated. Please retry later.",
        "soft limit": "Your soft limit could not be updated. Please retry later.",
    }, 
    "error-invoices-not-paid": "You have one or more unpaid pending invoices. Please update your payment methods and regularize the situation before changing your hard limit.",
    "error-not-verified-account": "You must verify your email address before changing your hard limit.",
    
    //Name
    flash_update_name_success: "Your name has been successfully updated.",
    flash_update_name_error: "Your name could not be updated. Please retry later.",
    
    
    //Password
    flash_reset_password_success: "An email to reset your password has been sent to you.",
    flash_reset_password_error: "Your password could not be reset. Please retry later.",
    
    
    //Customer infos
    flash_vat_unvalid: "Your VAT ID is not valid.",
    flash_update_customer_infos_success: "Your billing information has been updated successfully.",
    flash_update_customer_infos_error: "Your billing information could not be updated. Please retry later.",
    flash_update_customer_vat_id_success: "Your VAT ID has been updated successfully.",
    flash_update_customer_vat_id_error: "Your VAT ID could not be updated. Please retry later.",
    flash_missing_customer_infos: "Please fill in all the required fields.",
    
    
    //Email
    flash_email_unvalid: "This email is unvalid.",
    flash_update_email_error: "Your email could not be updated. Please retry later.",
    flash_update_email_success: "An email has just been sent to your new address. Please follow the instructions in this email to update your email address.",
    flash_email_errors:{
        proxyRejected_e: "The email address is not in a valid format.",
        proxyRejected_f: "The email address is not deliverable. Please check this or provide a valid email address.",
        proxyRejected_g: "The email address is disposable. Please provide an individual email address.",
        "connexion-too-old": "Your connection is too old to change your email address. Please log out and log back in to change your email address.",
    },
    

    //Generate Setup Intent
    flash_setup_intent_error: "Sorry, we could not generate a payment intent. Please try again later.",


    //Generate Payment Intent
    flash_payment_intent_error: "Sorry, we could not generate a payment intent. Please try again later.",
    flash_same_plan_error: "You are already subscribed to this plan. Please choose another plan to update your subscription.",
    prices_link: "/pricing/",

    
    //Generate Payment Intent
    flash_update_payment_intent_error: "Sorry, we could not update your payment intent. Please try again later.",
    
    
    //Add payment
    flash_add_payment_sucess: "The credit card has been added as a payment method.",
    flash_add_payment_error: "Your credit card has been rejected, please try again or contact your bank.",


    //Update payment
    flash_update_default_card_sucess: "Your default card has been updated successfully.",
    flash_update_default_card_error: "Sorry, we could not update your default card. Please try again later.",
    flash_update_cards_errors: {
        "error-card-expired": "The card you want to set as default has expired. Please choose a valid default payment card.",
    },


    //Resume payment
    flash_resume_payment_success: "Your payment has been resumed successfully.",
    flash_resume_payment_failed: "Your payment has been rejected. Please contact your bank or use another payment method.",
    flash_resume_payment_error: "Sorry, we could not resume your payment. Please try again later.",
    

    //Delete payment
    flash_delete_card_success: "Your card has been deleted successfully.",
    flash_delete_card_error: "Sorry, we could not delete your card. Please try again later.",
    flash_delete_cards_errors: {
        "error-default-card-not-deletable": "You cannot delete your default card. Please select another card as your default card before deleting this one.",
        "error-charge-waiting-payment": "You cannot delete your default card because you have one or more unpaid pending invoices. Please update your payment methods and regularize the situation before deleting this card.",
        "error-not-last-card": "Please choose another default card to be able to delete this credit card."
    },


    //Update plan
    return_flash_update_plan_success: plan => `Your subscription has been successfully updated to the ${plan} plan.`,
    return_flash_update_plan_error: plan => `Your subscription could not be updated to the ${plan} plan.`,
    flash_update_plan_errors: {
        "same-plan": "You are already subscribed to this plan. Please choose another plan to update your subscription.",
        "error-retrieving-setup-intent": "Sorry, we were unable to retrieve your payment information. Please refresh the page and try again.",
        "setup-intent-not-succeeded": "Your payment has been rejected. Please contact your bank or use another payment method.",
        "card-not-found": "Sorry, we are experiencing difficulties with the payment card you have selected. Please try again or select another payment method.",
        "card-expired": "The card you want to use has expired. Please choose a valid payment card.",
        "payment-not-succeeded": "Your payment has been rejected. Please contact your bank or use another payment method.",
    },

    //Delete account
    flash_delete_account_sucess: {
        deleted: "Your account and all information related to it have been deleted.",
        desactivated: "Your account has been deactivated successfully. The deletion will take several days to take effect.",
    },
    flash_delete_account_errors : {
        "error-cannot-be-deleted": "Your account cannot be deleted because you have a paid subscription still active or invoices awaiting payment.",
        proxyRejected_a: "Use of a VPN detected. For security reasons, please log out of your VPN to delete your account.",
        proxyRejected_b: "Use of a proxy detected. For security reasons, please log out of your proxy to delete your account.",
        proxyRejected_c: "Use of Tor detected. For security reasons, please use a browser other than Tor to delete your account.",
    },
    flash_delete_account_error: "Sorry, we could not delete your account. Please try again later.",
}
